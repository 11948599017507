import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import ImageUploading, { ErrorsType, ImageListType, ImageType } from "react-images-uploading";
import Button from "../Button";
import { ButtonType, FormattedImageListType, FormattedImageType } from "../../utils/@globalTypes";
import classNames from "classnames";
import styles from "./ImageMultipleComponent.module.scss";
import Tooltip from "../Tooltip";
import { IMAGE_ERROR } from "../../utils/constants";
import { DeleteIcon, EditIcon } from "../../assets/icons";

type ImageMultipleComponentProps = {
  title: string;
  // images: FormattedImageListType;
  newImages: FormattedImageListType;
  setNewImages: (value: React.SetStateAction<FormattedImageListType>) => void;
  setRemovedImages: (value: React.SetStateAction<FormattedImageListType>) => void;
  // setImage: (value: React.SetStateAction<string | null>) => void;
  setError: (value: React.SetStateAction<string>) => void;
  errText?: string;
  // noImageIcon: ReactNode;
  // newImageBtnTitle?: string;
  maxNumber?: number;
  maxFileSize?: number;
  acceptType?: string[];
  // resolutionType?: "less" | "more" | "absolute" | "ratio";
  resolutionWidth?: number;
  resolutionHeight?: number;
  // allowNonImageType?: boolean;
  orientation?: "vertical" | "horizontal";
  alt?: string;
  requirementsText: string | string[];
  // classNameImgWrapper?: string;
  classNameContainer?: string;
};

const ImageMultipleComponent: FC<ImageMultipleComponentProps> = ({
  title,
  // images,
  newImages,
  setNewImages,
  setRemovedImages,
  // setImage,
  setError,
  errText,
  // noImageIcon,
  // newImageBtnTitle,
  maxNumber,
  maxFileSize,
  acceptType,
  // resolutionType,
  resolutionWidth,
  resolutionHeight,
  // allowNonImageType,
  orientation,
  alt,
  requirementsText,
  // classNameImgWrapper,
  classNameContainer,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   console.log(formattedImagesList);
  // }, [formattedImagesList]);

  const [isDragging, setIsDragging] = useState(false);
  const [replacedImage, setReplacedImage] = useState<FormattedImageType | null>(null);

  const updateRemovedImages = (image: FormattedImageType) => {
    if (!image.value.isNew) {
      setRemovedImages((prevState) => [...prevState, image]);
    }
  };

  const onError = (errors: ErrorsType, files?: ImageListType) => {
    setIsDragging(false);

    // const error = errors?.acceptType || errors?.maxFileSize || errors?.maxNumber || errors?.resolution;

    // if (files) {
    //   const newValue: FormattedImageListType = files.map((image, index) => ({
    //     id: (index + 1) * Math.random(),
    //     value: {
    //       image,
    //       error: null,
    //     },
    //   }));

    //   if (errors?.acceptType) {
    //     newValue.forEach((item) => {
    //       const file = item.value.image.file;
    //       if (file) {
    //         const fileType = file.name.split(".").at(-1);
    //         console.log(fileType);

    //         if (acceptType && fileType && acceptType.includes(fileType)) {
    //           item.value.error = "Недопустимый формат изображения";
    //         }
    //       }
    //       // return item.value.image.size > maxFileSize ? {...item, value: {...item.value, error: }}
    //     });
    //   }

    //   if (errors?.maxFileSize && maxFileSize) {
    //     newValue.forEach((item) => {
    //       const file = item.value.image.file;
    //       if (file && file.size > maxFileSize) {
    //         item.value.error = "Размер файла превышает допустимый";
    //       }
    //       // return item.value.image.size > maxFileSize ? {...item, value: {...item.value, error: }}
    //     });
    //   }

    // if (errors?.maxNumber) {
    //   setError("")
    // }

    //   if (errors?.resolution) {
    //   }

    //   console.log(newValue);
    //   console.log(errors);
    //   console.log(formattedImagesList);

    //   setFormattedImagesList((prev) => [...prev, ...newValue]);
    //   setNewImages((prev) => [...prev, ...files]);
    // }

    // const img = new Image();
    // img.src = imgRef.current.src;

    // if (error && files) {
    //   // setNewImage([files[0]]);
    //   setError(IMAGE_ERROR);
    // }
  };

  const onChangeImage = (value: ImageListType, addUpdatedIndex?: number[] | undefined) => {
    setIsDragging(false);

    if (replacedImage) {
      setRemovedImages((prevState) => [...prevState, replacedImage]);
      setReplacedImage(null);
    }

    const newValue: FormattedImageListType = [];

    if (value.length) {
      nextStep(value[0], 0);
    } else {
      setNewImages([]);
    }

    function nextStep(item: any, index: number) {
      if (index < value.length) {
        const nextIndex = index + 1;

        if (item.value?.isNew === false) {
          newValue.push(item);
          nextStep(value[nextIndex], nextIndex);
        }

        const file = item.value?.image.file ?? item.file;
        const src = item.value?.image.data_url ?? item.data_url;
        const img = new Image();
        img.src = src;

        const setErrorHandler = () => {
          let error = null;

          switch (orientation) {
            case "vertical":
              if (img.width > img.height) {
                error = "Допускается только книжная ориентация изображения";
              }
              break;
            case "horizontal":
              if (img.width < img.height) {
                error = "Допускается только альбомная ориентация изображения";
              }
              break;
            default:
              break;
          }

          if (
            resolutionWidth &&
            resolutionHeight &&
            (img.width > resolutionWidth || img.height > resolutionHeight)
          ) {
            error = "Превышено максимально допустимое разрешение изображения";
          }

          if (file) {
            const fileType = file.name.split(".").at(-1);

            if (acceptType && fileType && !acceptType.includes(fileType)) {
              error = "Недопустимый формат изображения";
            }

            if (maxFileSize && file.size > maxFileSize) {
              error = "Размер файла превышает допустимый";
            }
          }

          newValue.push({
            id: (index + 1) * Math.random(),
            value: {
              image: item.value?.image ?? item,
              error,
              isNew: true,
            },
          });

          nextStep(value[nextIndex], nextIndex);
        };

        img.addEventListener("load", setErrorHandler);
      } else {
        setNewImages(newValue);
      }
    }
  };

  useEffect(() => {
    console.log(replacedImage);
  }, [replacedImage]);

  useEffect(() => {
    if (maxNumber && newImages.length > maxNumber) {
      setError("Превышено допустимое количество фото - 9");
    } else {
      setError("");
    }
  }, [newImages.length, maxNumber]);

  // const a = (newState: any, sortable: any, store: any) => {
  //   console.log(newState);
  //   console.log(sortable);
  //   console.log(store);
  // };

  // const b = (currentItem: any, evt: any) => {
  //   console.log(currentItem);
  //   console.log(evt);
  //   return currentItem;
  // };

  return (
    <div className={classNameContainer}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <Tooltip
          requirementsText={requirementsText}
          error={!!errText || newImages.some((image) => image.value.error)}
        />
      </div>
      <ImageUploading
        multiple={true}
        value={newImages}
        onChange={onChangeImage}
        dataURLKey="data_url"
        // maxNumber={maxNumber}
        // resolutionWidth={resolutionWidth}
        // resolutionHeight={resolutionHeight}
        // resolutionType={resolutionType}
        // maxFileSize={maxFileSize}
        // acceptType={acceptType}
        // allowNonImageType={allowNonImageType}
        onError={onError}
      >
        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => {
          return (
            <>
              <div>
                <div
                  className={classNames(styles.imagesContainer, {
                    [styles.emptuImagesContainer]: newImages.length === 0,
                    [styles.errContainer]: errText,
                  })}
                  ref={containerRef}
                  {...dragProps}
                  onDragEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                      setIsDragging(true);
                    }
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (
                      containerRef.current &&
                      !containerRef.current.contains(e.relatedTarget as any)
                    ) {
                      setIsDragging(false);
                    }
                  }}
                  onDragStart={(e) => {
                    // e.preventDefault();
                    // e.stopPropagation();
                    e.dataTransfer.clearData();
                    //   // console.log(e.dataTransfer);
                  }}
                >
                  <div
                    className={classNames(styles.dragOverlay, {
                      [styles.isDragging]: isDragging,
                    })}
                  >
                    Загрузить изображение
                  </div>
                  {newImages.length === 0 && <p>Перетащите сюда изображение</p>}
                  <ReactSortable
                    list={imageList as FormattedImageListType}
                    setList={setNewImages}
                    className={styles.imageList}
                    animation={200}
                    // clone={b}
                  >
                    {imageList.map((image, index) => {
                      return (
                        <div key={image.id}>
                          <div
                            className={classNames(styles.imgWrapper, {
                              [styles.errorImgWrapper]: image.value.error,
                            })}
                            // onDragStart={(e) => {
                            //   const target = e.currentTarget as HTMLDivElement;
                            //   // e.preventDefault();
                            //   // e.stopPropagation();
                            //   // console.log(e.dataTransfer);
                            //   // e.dataTransfer.setData("text/plain", index.toString());
                            //   if (target.lastElementChild) {
                            //     target.lastElementChild.style.opacity = "0";
                            //   }
                            //   console.log(e.currentTarget.lastElementChild);
                            // }}
                            // onDragOver={(e) => {
                            //   e.preventDefault();
                            //   const target = e.currentTarget as HTMLDivElement;

                            //   // console.log(e.currentTarget);
                            //   if (target.className === styles.imgWrapper) {
                            //     target.style.border = "1px solid green";
                            //   } else {
                            //     target.style.border = "";
                            //   }
                            // }}
                            // onDragEnd={(e) => {
                            //   console.log(e.currentTarget);
                            //   // e.currentTarget.style.display = "";
                            // }}
                            // onDragLeave={(e) => {
                            //   // console.log(e.currentTarget);
                            //   e.currentTarget.style.border = "";
                            // }}
                            // onDrop={(e) => {
                            //   // e.preventDefault();
                            //   e.currentTarget.style.border = "";
                            // }}
                            // draggable={true}
                          >
                            <img
                              src={image.value.image["data_url"] ?? image.value.image}
                              alt={alt}
                              draggable={false}
                            />
                            <div className={styles.imageBtns}>
                              <Button
                                title={<EditIcon />}
                                type={ButtonType.SMALL}
                                onClick={() => {
                                  setReplacedImage(image as FormattedImageType);
                                  onImageUpdate(index);
                                }}
                                onFocus={() => {
                                  if (replacedImage) {
                                    setTimeout(() => {
                                      setReplacedImage(null);
                                    }, 100);
                                  }
                                }}
                              />
                              <Button
                                title={<DeleteIcon />}
                                type={ButtonType.SMALL}
                                onClick={() => {
                                  updateRemovedImages(image as FormattedImageType);
                                  onImageRemove(index);
                                }}
                              />
                            </div>
                          </div>
                          {image.value.error && (
                            <p className={styles.errText}>{image.value.error}</p>
                          )}
                        </div>
                      );
                    })}
                  </ReactSortable>
                  {/* {imageList.length > 0 ? (
                    <img ref={imgRef} src={imageList[0]["data_url"]} alt={alt} />
                  ) : image ? (
                    <img src={image} alt={alt} />
                  ) : (
                    noImageIcon
                  )} */}
                </div>
                <div className={styles.imgAddBtnWrapper}>
                  <Button title="Добавить фото" type={ButtonType.LINK} onClick={onImageUpload} />
                  {errText && <p className={styles.errText}>{errText}</p>}
                </div>
                {/* <div className={styles.btnsImagesListWrapper}>
                  <div className={styles.btnsWithTooltipWrapper}>
                    <Button
                      title={isImage ? "Изменить" : newImageBtnTitle || "Добавить фото"}
                      type={ButtonType.LINK}
                      onClick={newImage.length > 0 ? () => onImageUpdate(0) : onImageUpload}
                    />
                    <Tooltip requirementsText={requirementsText} error={!!errText} />
                  </div>
                  {newImage.length > 0 && (
                    
                  )}
                </div> */}
                {/* <div
                  className={classNames(styles.imgWrapper, classNameImgWrapper, {
                    [styles.transparentImgWrapper]: image || newImage.length > 0,
                  })}
                >
                  <div
                    className={classNames(styles.dragOverlay, {
                      [styles.isDragging]: isDragging,
                    })}
                    {...dragProps}
                  >
                    Загрузить изображение
                  </div>
                  {imageList.length > 0 ? (
                    <img ref={imgRef} src={imageList[0]["data_url"]} alt={alt} />
                  ) : image ? (
                    <img src={image} alt={alt} />
                  ) : (
                    noImageIcon
                  )}
                </div>
                <div className={styles.btnsImagesListWrapper}>
                  <div className={styles.btnsWithTooltipWrapper}>
                    <Button
                      title={isImage ? "Изменить" : newImageBtnTitle || "Добавить фото"}
                      type={ButtonType.LINK}
                      onClick={newImage.length > 0 ? () => onImageUpdate(0) : onImageUpload}
                    />
                    <Tooltip requirementsText={requirementsText} error={!!errText} />
                  </div>
                  {newImage.length > 0 && (
                    <Button
                      title="Удалить"
                      type={ButtonType.LINK}
                      onClick={newImage.length > 0 ? () => onImageRemove(0) : () => setImage(null)}
                      className={styles.removeImageBtn}
                    />
                  )}
                </div> */}
              </div>
            </>
          );
        }}
      </ImageUploading>
    </div>
  );
};

export default ImageMultipleComponent;
