import { FormattedImageListType, OptionType } from "../../utils/@globalTypes";
import {
  LimitWithOffsetPayload,
  PayloadDataWithId,
  PayloadWithCallback,
  ResponseData,
  ResponseOptionsData,
} from "./@types";
import { MainObject, ObjectTypeData } from "./mainObjectTypes";
import { CertainDayPriceTypeList, PriceType } from "./pricesTypes";

// STATE TYPES

export enum RentalObjectStatusesEnum {
  AFB = "afb",
  CFB = "cfb",
  MNT = "mnt",
}

export type RentalObject = {
  id: number;
  main_object: MainObject;
  name: string;
  description: string | null;
  status: string;
  max_places: number | null;
  object_type: OptionType;
  image: RentalImage | null;
};

export type RentalObjectList = RentalObject[];

export type FeatureType = {
  name: string;
  logo: string;
};

export type RentalObjectFeature = FeatureType & {
  id: number;
};

export type RentalObjectFeaturesList = RentalObjectFeature[];

export type CurrentRentalObject = {
  id: number;
  main_object: {
    id: string;
    name: string;
    object_type: ObjectTypeData;
  };
  name: string;
  object_type: ResponseOptionsData;
  description: string | null;
  status: string;
  max_places: number | null;
  image: string | null;
  price: PriceType;
  certain_day_prices: CertainDayPriceTypeList;
  booking_setting: {
    check_in: string;
    check_out: string;
    min_days_high_season: number;
    min_days_low_season: number;
  };
};

export type BedType = {
  double_bed: number;
  single_bed: number;
  children_bed: number;
  extra_sleeping_place: number;
};

export type RentalImage = {
  id: number;
  image: string;
  order: string;
  rental_object: number;
};

export type RentalImagesList = RentalImage[];

// PAYLOAD TYPES

export type CreateRentalObjectPayload = {
  name: string;
  main_object: string;
  object_type: number;
};

export type UpdateRentalObjectData = {
  name?: string;
  description?: string;
  max_places?: number;
  object_type?: number;
  status?: string;
  features?: FeatureType[];
};

export type UpdateRentalImagesData = {
  images: FormattedImageListType;
  rental_object: number;
  removedIdList: number[];
  clearRemovedListCallback: () => void;
};

export type UpdateRentalObjectPayload = PayloadDataWithId<UpdateRentalObjectData>;

export type CreateRentalObjectPayloadData = PayloadWithCallback<CreateRentalObjectPayload>;

export type GetRentalObjectListPayload = LimitWithOffsetPayload & { main_object: string };

// RESPONSE TYPES

export type RentalObjectsResponseData = ResponseData<RentalObjectList>;

export type RentalObjectTypesResponseData = ResponseData<ResponseOptionsData[]>;
export type RentalObjectFeaturesResponseData = ResponseData<RentalObjectFeaturesList>;
