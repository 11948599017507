import React from "react";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { FormattedImageListType, OptionsListType } from "../../utils/@globalTypes";
import { ObjectTypesPayload } from "../types/mainObjectTypes";
import {
  CreateRentalObjectPayloadData,
  CurrentRentalObject,
  FeatureType,
  GetRentalObjectListPayload,
  RentalImagesList,
  RentalObjectFeaturesList,
  RentalObjectList,
  RentalObjectStatusesEnum,
  UpdateRentalImagesData,
  UpdateRentalObjectPayload,
} from "../types/rentalObjectTypes";

type RentalObjectState = {
  rentalObjectList: RentalObjectList;
  rentalObjectTypes: OptionsListType;
  rentalObjectStatuses: OptionsListType;
  currentRentalObject: CurrentRentalObject | null;
  currentRentalObjectFeatures: RentalObjectFeaturesList;
  currentRentalImages: RentalImagesList;
};

const initialState: RentalObjectState = {
  rentalObjectList: [],
  rentalObjectTypes: [],
  rentalObjectStatuses: [
    { value: RentalObjectStatusesEnum.AFB, label: "Доступен к бронированию", color: "#42AC4D" },
    { value: RentalObjectStatusesEnum.CFB, label: "Закрыт к бронированию", color: "#B91212" },
    { value: RentalObjectStatusesEnum.MNT, label: "Техобслуживание", color: "#B91212" },
  ],
  currentRentalObject: null,
  //
  currentRentalObjectFeatures: [],
  currentRentalImages: [],
};

const RentalObjectSlice = createSlice({
  name: "rentalObject",
  initialState,
  reducers: {
    getRentalObjectTypes(_, __: PayloadAction<ObjectTypesPayload>) {},
    setRentalObjectTypes(state, action: PayloadAction<OptionsListType>) {
      state.rentalObjectTypes = action.payload;
    },
    getRentalObjectFeatures(_, __: PayloadAction<number>) {},
    setRentalObjectFeatures(state, action: PayloadAction<FeatureType[]>) {
      state.currentRentalObjectFeatures = action.payload.map((item, index) => ({
        id: (index + 1) * Math.random(),
        ...item,
      }));
    },
    createRentalObject(_, __: PayloadAction<CreateRentalObjectPayloadData>) {},
    getRentalObjectList(_, __: PayloadAction<GetRentalObjectListPayload>) {},
    setRentalObjectList(state, action: PayloadAction<RentalObjectList>) {
      state.rentalObjectList = action.payload;
    },
    removeRentalObject(_, __: PayloadAction<{ main_object: string; rental_object: number }>) {},
    removeRentalObjectFromList(state, action: PayloadAction<number>) {
      state.rentalObjectList = state.rentalObjectList.filter(
        (object) => object.id !== action.payload
      );
    },
    setIsLoadingRentalObjectCard(state, action: PayloadAction<{ id: number; value: boolean }>) {
      const { id, value } = action.payload;

      state.rentalObjectList = state.rentalObjectList.map((object) => {
        return object.id === id ? { ...object, isLoading: value } : object;
      });
    },
    // Rental Base Info
    getRentalObjectTabsData(_, __: PayloadAction<number>) {},
    getCurrentRentalObject(_, __: PayloadAction<number>) {},
    setCurrentRentalObject(state, action: PayloadAction<CurrentRentalObject | null>) {
      state.currentRentalObject = action.payload;
    },
    updateRentalObject(_, __: PayloadAction<UpdateRentalObjectPayload>) {},
    // Rental Images
    updateRentalObjectImages(_, __: PayloadAction<UpdateRentalImagesData>) {},
    getCurrentRentalImages(_, __: PayloadAction<number>) {},
    setCurrentRentalImages(state, action: PayloadAction<RentalImagesList>) {
      state.currentRentalImages = action.payload;
    },
    // removeRentalObjectImages(_, __: PayloadAction<number[]>) {},
    // addNewImages(state, action: PayloadAction<RentalImagesResponseData>) {
    //   state.currentRentalImages = [...state.currentRentalImages, ...action.payload];
    // },
  },
});

export const {
  getRentalObjectTypes,
  setRentalObjectTypes,
  getRentalObjectList,
  setRentalObjectList,
  createRentalObject,
  removeRentalObject,
  removeRentalObjectFromList,
  setIsLoadingRentalObjectCard,
  getRentalObjectTabsData,
  setCurrentRentalObject,
  updateRentalObject,
  getCurrentRentalObject,
  getRentalObjectFeatures,
  setRentalObjectFeatures,
  updateRentalObjectImages,
  getCurrentRentalImages,
  setCurrentRentalImages,
  // removeRentalObjectImages,
  // addNewImages,
} = RentalObjectSlice.actions;

export default RentalObjectSlice.reducer;

export const rentalObjectName = RentalObjectSlice.name;

export const RentalObjectSelectors = {
  getRentalObjectList: (state: RootState) => state.rentalObject.rentalObjectList,
  getRentalObjectTypes: (state: RootState) => state.rentalObject.rentalObjectTypes,
  getRentalObjectStatuses: (state: RootState) => state.rentalObject.rentalObjectStatuses,
  getCurrentRentalObject: (state: RootState) => state.rentalObject.currentRentalObject,
  getCurrentFeaturesList: (state: RootState) => state.rentalObject.currentRentalObjectFeatures,
  getCurrentImagesList: (state: RootState) => state.rentalObject.currentRentalImages,
};
